<template>
  <b-card class="earnings-card">
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1"
          ><h1>Hotspot total: {{ total }}</h1>
        </b-card-title>

        <b-card-text class="text-muted font-small-2"
          ><br />
          <h5>
            <span> Hotspot : </span>
            <span class="font-weight-bolder">100 %</span
            ><br /><br />
            <span> Online : </span>
            <span class="font-weight-bolder">{{ hotspot_Online_total }} %</span>
            <br /><br />
            <span> Offline : </span>
            <span class="font-weight-bolder"
              >{{ hotspot_Offline_total }} %</span
            >
            <br /><br />
            <span> N/A : </span>
            <span class="font-weight-bolder">{{ hotspot_NA_total }}%</span>
          </h5>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          height="250"
          :options="earningsChart.chartOptions"
          :series="earningsChart.series"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12"> <dash2></dash2> </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import api from "@/api";
import dash2 from "./dash2.vue";
const $earningsStrokeColor2 = "#51c781";
const $earningsStrokeColor3 = "#e41b1b";
const $earningsStrokeColor1 = "#e4a328";
const $earningsStrokeColor0 = "#424242";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
    dash2,
  },
  data() {
    return {
      earningsChart: {
        series: [],
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ["Online", "Offline", "N/A"],
          stroke: { width: 0 },
          colors: [
            $earningsStrokeColor2,
            $earningsStrokeColor3,
            $earningsStrokeColor1,
            $earningsStrokeColor0,
          ],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`;
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: "Hotspot",
                  },
                },
              },
            },
          },
        },
      },
      total: 0,
      sum: [],
      hotspot_Online_total: 0,
      hotspot_Offline_total: 0,
      hotspot_NA_total: 0,
      total_hotspot: 0,
    };
  },
  mounted() {
    this.load_data();
  },
  methods: {
    load_data() {
      api.post("/dashapi").then((response) => {
        this.total = response.data.total_hotspot;
        this.hotspot_Online_total = Math.round(
          (response.data.hotspot_Online_total * 100) /
            response.data.total_hotspot
        );
        this.hotspot_Offline_total = Math.round(
          (response.data.hotspot_Offline_total * 100) /
            response.data.total_hotspot
        );

        this.hotspot_NA_total = Math.round(
          (response.data.hotspot_NA_total * 100) / response.data.total_hotspot
        );

        this.earningsChart.series.push(this.hotspot_Online_total);
        this.earningsChart.series.push(this.hotspot_Offline_total);
        this.earningsChart.series.push(this.hotspot_NA_total);
      });
    },
  },
};
</script>
