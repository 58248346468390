<template>
  <div>
    <!-- ************************************* -->
    <div class="demo-inline-spacing">
      <div><h1>Vanue</h1></div>
      <div>
        <h2>Total : {{ totalRows }}</h2>
      </div>
      <br />
      <!-- filter -->
      <b-form-group
        label="Filter"
        label-cols-sm="2"
        label-align-sm="left"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-button
        v-b-toggle.report-list
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        variant="outline-primary"
      >
        Show Hotport
      </b-button>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(actions)="data">
          <template>
            <app-timeline>
              <app-timeline-item variant="success">
                <div
                 
                >
                  <h2>{{ data.item.title }}</h2>
                  <div v-for="data_V in venue_sum" :key="data_V.venue_id">
                    <div v-if="data_V.venue_id === data.item.id">
                      <b-badge variant="success"
                        >Online : {{ data_V.HPvenueOn }} </b-badge
                      > ||
                      <b-badge variant="danger">
                        Offline : {{ data_V.HPvenueOff }} </b-badge
                      > ||
                      <b-badge variant="warning">
                        Dormant : {{ data_V.HPvenueNo }} </b-badge
                      > ||
                      <b-badge variant="dark">
                        Not status : {{ data_V.HPvenueDont }} </b-badge
                      > ||
                      <b-badge variant="info"
                        >total : {{ data_V.HPvenueSum }}
                      </b-badge>
                    </div>
                  </div>
                </div>

                <b-collapse id="report-list" v-model="show">
                  <b-list-group>
                    <div v-for="value in hotspot_venue" :key="value.id">
                      <b-list-group-item v-if="value.venue_id === data.item.id">
                        <h5>mac: {{ value.mac }}</h5>
                        <h5>type: {{ value.type }}</h5>
                        <h5>lastseen: {{ value.lastseen }}</h5>
                        <h5>description: {{ value.description }}</h5>
                        <template
                          v-if="
                            value.status == 'online' || value.status == 'Online'
                          "
                        >
                          status: <b-badge variant="success">Online </b-badge>
                        </template>
                        <template
                          v-else-if="
                            value.status == 'offline' ||
                            value.status == 'Offline'
                          "
                        >
                          status:  <b-badge variant="danger"> Offline </b-badge>
                        </template>
                        <template
                          v-else-if="
                            value.status == 'Flagged' ||
                            value.status == 'alerting' ||
                            value.status == 'dormant'
                          "
                        >
                          status: <b-badge variant="warning"> Dormant </b-badge>
                        </template>
                        <template v-else>
                          status: 
                          <b-badge variant="dark"> ไม่พบสถานะ </b-badge>
                        </template>
                       
                      </b-list-group-item>
                    </div>

                    <!-- ************ -->
                  </b-list-group>
                </b-collapse>
              </app-timeline-item>
            </app-timeline>
          </template>
        </template>
      </b-table>
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BImg,
  BButton,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  BBadge,
  VBTooltip,
  BTable,
  BPagination,
  BInputGroup,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BAvatar,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import api from "@/api";
export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BImg,
    BButton,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    BTable,
    BPagination,
    BInputGroup,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BAvatar,
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
  data: () => ({
    perPage: 10,
    pageOptions: [10, 20, 30],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    fields: [{ key: "actions", label: "title" }],
    items: [],
    venue_sum: null,
    hotspot_venue: null,
    show: false,
  }),
  mounted() {
    this.load_data();
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    load_data() {
      api.post("/dashapi").then((response) => {
        this.items = response.data.venues;
        this.totalRows = response.data.venues_total;
        this.hotspot_venue = response.data.hotspot_venue;
        this.venue_sum = response.data.venue_sum;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.show = false;
    },
  },
};
</script>