<template>
  <div class="demo-inline-spacing">
    <div><h1>Off Line</h1></div>
    <div>
      <h2>จำนวน : {{ totalRows }}</h2>
    </div>
    <br />
    <!-- filter -->
    <b-form-group
      label="Filter"
      label-cols-sm="2"
      label-align-sm="left"
      label-size="sm"
      label-for="filterInput"
      class="mb-0"
    >
      <b-input-group size="sm">
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          placeholder="Type to Search"
        />
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(status)="data">
        <template v-if="data.item.status">
          <b-badge variant="danger">Offlone </b-badge>
        </template>
      </template>
    </b-table>
    <b-form-group
      label="Per Page"
      label-cols="6"
      label-align="left"
      label-size="sm"
      label-for="sortBySelect"
      class="text-nowrap mb-md-0 mr-1"
    >
      <b-form-select
        id="perPageSelect"
        v-model="perPage"
        size="sm"
        inline
        :options="pageOptions"
      />
    </b-form-group>

    <!-- pagination -->
    <div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BPagination,
  BInputGroup,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import api from "@/api";
export default {
  components: {
    BButton,
    BTable,
    BPagination,
    BInputGroup,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    perPage: 10,
    pageOptions: [10, 20, 30],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    fields: [
      {
        key: "codename",
        label: "codename",
        sortable: false,
      },
      {
        key: "mac",
        label: "mac",
        sortable: false,
      },
      {
        key: "lastseen",
        label: "lastSeen",
        sortable: false,
      },
      {
        key: "status",
        label: "status",
        sortable: false,
      },
       {
        key: "type",
        label: "type ",
        sortable: false,
      },
    ],

    items: [],
  }),
  mounted() {
    this.load_data();
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    load_data() {
      api.post("/dashapi").then((response) => {
        this.items = response.data.hotspot_Offline;
        this.totalRows = response.data.hotspot_Offline_total;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>