<template>
  <b-card no-body class="card-statistics">
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import api from "@/api";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: "WifiIcon",
          color: "light-success",
          title: "",
          subtitle: "Online",
          customClass: "",
        },
        {
          icon: "WifiOffIcon",
          color: "light-danger",
          title: "",
          subtitle: "Offline",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "ZapOffIcon",
          color: "light-info",
          title: "",
          subtitle: "N/A",
          customClass: "mb-2 mb-xl-0",
        },

      ],
      unknow_Online_total: 0,
      unknow_Offline_total: 0,
      unknow_NA_total: 0,


    };
  },
  mounted() {
    this.load_data();
  },
  methods: {
    load_data() {
      api.post("/dashapi").then((response) => {
        console.log("response", response.data);
        this.statisticsItems[0]["title"] = response.data.unknow_no_on_total;
        this.statisticsItems[1]["title"] = response.data.unknow_no_off_total;
        this.statisticsItems[2]["title"] = response.data.unknow_no_NA_total;



      });
    },
  },
};
</script>
