<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      type="submit"
      variant="dark"
      @click="fetch_data()"
    >
      ดึงข้อมูล
    </b-button>

    <!-- <b-row>
      <b-col cols="2"> </b-col>
      <b-col cols="5"> </b-col>
      <b-col cols="5"> </b-col>
    </b-row> -->

    <b-tabs pills vertical>
      <b-tab title="DashBoards" active>
        <b-row>
          <b-col cols="12"> <dashall></dashall> </b-col> 
        </b-row>
        <b-row>
          <b-col cols="12"> <dash1></dash1> </b-col>
          
        </b-row>
        <b-row>
        <b-col cols="12"> <dasshunknow_no></dasshunknow_no> </b-col>
        </b-row>
        <!-- <b-row>
        <b-col cols="12"> <dash3></dash3> </b-col>
        </b-row> -->
      </b-tab>
      <!-- <b-tab title="สรุปรวม 7 วันย้อนหลัง"> </b-tab> -->
      <b-tab title="hotspot">
        <h2>จำนวนทั้งหมด: {{ total }}</h2>
        <b-tabs fill pills>
          <b-tab title="Online" active>
            <DashApiOn></DashApiOn>
          </b-tab>
          <b-tab title="Offline">
            <DashApiOff></DashApiOff>
          </b-tab>

          <b-tab title="other">
            <DashApiDown></DashApiDown>
          </b-tab>
        </b-tabs>
      </b-tab>
      <b-tab title="unknow"> <UnknowNotDone></UnknowNotDone> </b-tab>
      <b-tab title="vuene"> <dash3></dash3> </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BButton, BTabs, BTab, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import api from "@/api";
import DashApiOn from "./HP_online.vue";
import DashApiOff from "./HP_offline.vue";
import DashApiDown from "./HP_NA.vue";
import UnknowNotDone from "./unknow.vue";
import dash1 from "./dash1.vue";
import dash2 from "./dash2.vue";
import dash3 from "./dash3.vue"
import dashall from "./dashall.vue"
import dasshunknow_no from "./dashunknow_no.vue"
export default {
  components: {
    BButton,
    DashApiOn,
    DashApiOff,
    DashApiDown,
    UnknowNotDone,
    dasshunknow_no,
    dash1,
    dash2,
    dash3,
    dashall,
    BTabs,
    BTab,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    total: 0,
  }),
  mounted() {
    this.load_data();
  },
  computed: {},
  methods: {
    load_data() {
      api.post("/dashapi").then((response) => {
        this.total = response.data.total_hotspot;
      });
    },
    fetch_data() {
      console.log("fetch_data");
      api.post("/fetchdata").then((response) => {
        console.log(response.data);
        this.items = response.data.device;
        // this.items = response.data.data1.device;
        this.totalRows = response.data.totalCount;
        this.$swal({
          title: "Good job!",
          text: "You clicked the button!",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    },
  },
};
</script>