<template>
  <b-card class="earnings-card">
    <b-row>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          height="250"
          :options="earningsChart.chartOptions"
          :series="earningsChart.series"
        />
      </b-col>
      <b-col cols="6">
        <b-card-title class="mb-1"
          ><h1>Hotspot Unknow: {{ unknow_no_total }}</h1>
        </b-card-title>

        <b-card-text class="text-muted font-small-2"
          ><br />
          <h5>
            <span> unknow : </span>
            <span class="font-weight-bolder">{{ unknow_no_totalP }} % ( {{ unknow_no_total }} ) total {{total}} </span>
          </h5>
        </b-card-text>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12"> <UN_dash2></UN_dash2> </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import api from "@/api";
import UN_dash2 from "./UN_dash2.vue";
const $earningsStrokeColor2 = "#51c781";
const $earningsStrokeColor3 = "#e41b1b";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
    UN_dash2,
  },
  data() {
    return {
      earningsChart: {
        series: [],
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ["Done", "unknow"],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`;
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: "Hotspot",
                  },
                },
              },
            },
          },
        },
      },
      total: 0,
      sum: [],
      unknow_done_total: 0,
      unknow_no_totalP: 0,
      unknow_no_total: 0,
    };
  },
  mounted() {
    this.load_data();
  },
  methods: {
    load_data() {
      api.post("/dashapi").then((response) => {
        this.total = response.data.unknow_all_total;
        this.unknow_no_totalP = Math.round(
          (response.data.unknow_no_total * 100) / response.data.unknow_all_total
        );
        this.unknow_done_total = Math.round(
          (response.data.unknow_done_total * 100) / response.data.unknow_all_total
        );
        this.unknow_no_total = response.data.unknow_no_total;
        this.earningsChart.series.push(this.unknow_done_total);
        this.earningsChart.series.push(this.unknow_no_totalP);
      });
    },
  },
};
</script>
