<template>
  <b-card no-body>
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title><h2>Hotspot Data For API</h2></b-card-title>
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>
        <b-col sm="2" class="d-flex flex-column flex-wrap text-center">
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ unknow_all_total }}
          </h1>
        </b-col>

        <!-- chart -->
        <b-col sm="10" class="d-flex justify-content-center">
          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="supportTrackerRadialBar.series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between">
        <div class="text-center">
          <b-card-text class="mb-50"> Online </b-card-text>
          <span class="font-large-1 font-weight-bold">{{
            unknow_Online_total
          }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50"> Offline </b-card-text>
          <span class="font-large-1 font-weight-bold">{{
            unknow_Offline_total
          }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50"> N/A </b-card-text>
          <span class="font-large-1 font-weight-bold">{{
            unknow_NA_total
          }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import api from "@/api";
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  data() {
    return {
      trackerData: {},
      supportTrackerRadialBar: {
        series: [],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%",
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: "#5e5873",
                  fontSize: "1rem",
                },
                value: {
                  offsetY: 15,
                  color: "#5e5873",
                  fontSize: "1.714rem",
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ["Online"],
        },
      },
      unknow_NA_total: 0,
      unknow_Offline_total: 0,
      unknow_Online_total: 0,
      unknow_all_total: 0,
      total: 0,
    };
  },
  mounted() {
    this.load_data_unknow();
  },
  methods: {
    load_data_unknow() {
      api.post("/dashapi").then((response) => {
        console.log("dashall" + response.data);
        this.unknow_NA_total = response.data.unknow_NA_total;
        this.unknow_Offline_total = response.data.unknow_Offline_total;
        this.unknow_Online_total = response.data.unknow_Online_total;
        this.unknow_all_total = response.data.unknow_all_total;
        this.total =Math.round(
          (response.data.unknow_Online_total * 100) /
          response.data.unknow_all_total);
        console.log("% total :>> ", this.total);
        this.supportTrackerRadialBar.series.push(this.total);
      });
    },
  },
};
</script>
